<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap justify-center fill-height v-if="mapData.length < 1">
      <v-flex xs12 text-center align-self-center>
        <span class="itemKey">No Hives</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card outlined>
          <v-layout wrap justify-center>
            <v-flex xs12 pa-3>
              <div id="map"></div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyCaccNxbzwR9tMvkppT7bT7zNKjChc_yAw&libraries=visualization,drawing">
</script>
<script>
import axios from "axios";
var marker;
export default {
  props: ["userId"],
  components: {},
  data() {
    return {
      appLoading: false,
      ServerError: false,
      map: "",
      mapData: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/colony/admingetlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          userid: this.userId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.mapData = response.data.data;

            var vm = this;
            var mapCanvas = document.getElementById("map");
            if (this.mapData.length > 0) {
              var mapOptions = {
                center: new google.maps.LatLng(
                  this.mapData[0].location[1],
                  this.mapData[0].location[0]
                ),
                zoom: 18,

                mapTypeId: google.maps.MapTypeId.TERRAIN,
              };

              vm.map = new google.maps.Map(mapCanvas, mapOptions);

              for (var i = 0; i < this.mapData.length; i++) {
                var lat = this.mapData[i].location[1];
                var lon = this.mapData[i].location[0];
                var latlngset = new google.maps.LatLng(lat, lon);
                var color = "yellow";
                if (this.mapData[i].colonystatus == "Excellent")
                  color = "green";
                if (this.mapData[i].colonystatus == "Good") color = "yellow";
                if (this.mapData[i].colonystatus == "Ok") color = "orange";

                if (this.mapData[i].colonystatus == "Not ok") color = "red";
                var icon = {
                  url:
                    "http://arhiveadmin.leopardtechlabs.com/img/" +
                    color +
                    "marker.png", // url
                  scaledSize: new google.maps.Size(20, 32), // scaled size
                  origin: new google.maps.Point(0, 0), // origin
                  anchor: new google.maps.Point(0, 0), // anchor
                };
                marker = new google.maps.Marker({
                  map: vm.map,
                  position: latlngset,
                  icon: icon,
                });
                var content =
                  `
            <div class="v-card v-card--flat v-sheet theme--light" style="max-width: 200px;">
                <div class="layout wrap justify-start pa-2">
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">
                        Hive ID : 
                        </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                  this.mapData[i].colonyidentifier +
                  `</span>
                    </div>
                    <div class="flex xs12 text-left">
                        <span class="itemValue" style="color: rgb(253, 206, 72);">Hive Status : </span>
                        <span class="itemKey pl-2" style="color: rgb(0, 0, 0);">` +
                  this.mapData[i].colonystatus +
                  `</span>
                    </div>
                    <div class="flex xs12 text-center py-1">
                        <a href="/Hive/` +
                  this.mapData[i]._id +
                  `" aria-current="page" class="itemKey text-capitalize v-btn--active v-btn v-btn--has-bg v-btn--router theme--light v-size--small"
                        style="background-color: rgb(253, 206, 72);">
                            <span class="v-btn__content">
                                <span style="color: rgb(255, 255, 255);"> View Details </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
          `;

                var infowindow = new google.maps.InfoWindow();

                google.maps.event.addListener(
                  marker,
                  "click",
                  (function (marker, content, infowindow) {
                    return function () {
                      infowindow.setContent(content);
                      infowindow.open(vm.map, marker);
                    };
                  })(marker, content, infowindow)
                );
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>