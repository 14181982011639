<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span style="font-family: poppinsbold; font-size: 20px; color: #000">
          User Details
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12>
        <UserDetails v-bind:storage="userData" v-bind:inventory="inventory" />
      </v-flex>
      <v-flex xs12 py-2>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 py-2>
        <v-tabs v-model="tab">
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab v-for="item in tabs" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-flex>
      <v-flex v-if="tab == 0">
        <Apiaries v-bind:userId="$route.params.id" />
      </v-flex>
      <v-flex v-if="tab == 1">
        <Hives v-bind:userId="$route.params.id" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import UserDetails from "./User/userDetails";
import Apiaries from "./User/apiaries";
import Hives from "./User/hives";
export default {
  props: ["id"],
  components: {
    UserDetails,
    Apiaries,
    Hives,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      userData: {},
      tab: 0,
      tabs: ["Apiaries", "Hives"],
      inventory:[]
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: { id: this.id },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.appLoading = false;
            this.userData = response.data.data;
            this.inventory=response.data.inventory
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.appLoading = false;
        });
    },
  },
};
</script>