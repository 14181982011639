<template>
  <div>
    <v-layout wrap justify-start>
      <v-flex xs12 sm2 lg2 xl3 text-center align-self-center>
        <v-avatar color="#FFF9C4" size="120px">
          <v-img v-if="storage.photo" :src="mediaURL + storage.photo">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="#FDCE48"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <span v-else>No Image</span>
        </v-avatar>
      </v-flex>
      <v-flex xs12 sm6 md7 lg7 xl6 pl-sm-2>
        <v-layout wrap justify-center py-2>
          <v-flex xs6 sm6 md4 text-left>
            <span class="itemKey">Full Name</span> <br />
            <span class="itemValue" v-if="storage.firstname">
              {{ storage.firstname }} {{ storage.lastname }}
            </span>
            <span v-else class="itemValue"> - </span>
          </v-flex>
          <v-flex xs6 sm6 md4 text-left>
            <span class="itemKey">Username</span> <br />
            <span v-if="storage.username" class="itemValue">
              {{ storage.username }}
            </span>
            <span v-else class="itemValue"> - </span>
          </v-flex>
          <v-flex xs6 sm6 md4 text-left>
            <span class="itemKey">Email</span> <br />
            <span v-if="storage.email" class="itemValue">
              {{ storage.email }}
            </span>
            <span v-else class="itemValue"> - </span>
          </v-flex>
          <v-flex xs6 sm6 md4 text-left>
            <span class="itemKey">Phone</span> <br />
            <span v-if="storage.phone" class="itemValue">
              {{ storage.phone }}
            </span>
            <span v-else class="itemValue"> - </span>
          </v-flex>
          <v-flex xs6 sm6 md4 text-left>
            <span class="itemKey">Gender</span> <br />
            <span v-if="storage.gender" class="itemValue">
              {{ storage.gender }}
            </span>
            <span v-else class="itemValue"> - </span>
          </v-flex>
          <v-flex xs6 sm6 md4 text-left>
            <span class="itemKey">Location</span> <br />
            <span v-if="storage.locationname" class="itemValue">
              {{ storage.locationname }}
            </span>
            <span v-else class="itemValue"> - </span>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 sm4 md3 lg3 xl3 v-if="inventory.length > 0">
        <v-card>
          <v-layout wrap justify-center pa-3>
            <v-flex xs12 pb-2>
              <span
                style="
                  font-family: poppinsmedium;
                  font-size: 16px;
                  color: #545353;
                "
              >
                Updated Inventory
              </span>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap justify-start>
                <v-flex xs10 text-left class="itemKey">Type</v-flex>
                <v-flex xs2 text-left class="itemKey">Amount</v-flex>
              </v-layout>
              <v-layout
                wrap
                justify-start
                v-for="(item, i) in inventory"
                :key="i"
              >
                <v-flex xs10 text-left class="itemValue">
                  {{ item.honeytype }}
                </v-flex>
                <v-flex xs2 text-center class="itemValue">
                  {{ item.amount }}
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- <v-flex xs6 text-center>
              <span style="font-size: 20px" class="itemKey"> Quantity </span>
              <br />
              <span style="font-size: 20px" class="itemValue">9KG</span>
            </v-flex>
            <v-flex xs6 text-center>
              <span style="font-size: 20px" class="itemKey"> Amount </span>
              <br />
              <span style="font-size: 20px" class="itemValue">580</span>
            </v-flex> -->
            <!-- <v-flex xs12 text-center>
              <span
                style="
                  font-family: poppinsmedium;
                  font-size: 14px;
                  color: #000000;
                "
                class="itemKey"
              >
                click to view details
              </span>
            </v-flex> -->
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage", "inventory"],
  data() {
    return {};
  },
};
</script>